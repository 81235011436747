/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
// Import core & vendor packages below
import { toast } from 'react-toastify';
import { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Image, Container } from 'react-bootstrap';
import { passwordStrength } from 'check-password-strength';

// Components
import FormText from 'components/GlobalUIs/FormText';
import SuccessModal from './components/SuccessModal';
import ButtonSubmit from 'components/GlobalUIs/ButtonSubmit';
import { forgotPassword, selectAuth, resetForgotState } from 'reducers/authReducer';

// Import assets below
import styles from './index.module.scss';
import LoginImage from 'assets/images/login Image.png';
import { ReactComponent as UserSVG } from 'assets/svg/username.svg';

const initialState = {
  password: '',
  emailAddress: '',
  confirmPassword: '',
};

const passwordOptions = [
  {
    id: 0,
    value: 'Too weak Password',
    minDiversity: 0,
    minLength: 0,
  },
  {
    id: 1,
    value: 'Weak Password',
    minDiversity: 1,
    minLength: 4,
  },
  {
    id: 2,
    value: 'Medium Password',
    minDiversity: 3,
    minLength: 6,
  },
  {
    id: 3,
    value: 'Strong Password',
    minDiversity: 4,
    minLength: 8,
  },
];

// Main component
const ForgotPassword = () => {
  const dispatch = useDispatch();

  const authState = useSelector(selectAuth);
  const { forgotPassLogs } = authState;

  const navigate = useNavigate();

  const [showModal, setShowModal] = useState(false);
  const [formData, setFormData] = useState(initialState);
  const [passwordCheck, setPasswordCheck] = useState({
    value: '',
    contains: [],
  });

  const handleLogin = () => {
    navigate('/');
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const formDataVal = new FormData();
    formDataVal.append('email', formData?.emailAddress);

    dispatch(forgotPassword(formDataVal));
  };

  const emailProps = {
    Icon: UserSVG,
    type: 'email',
    haveIcon: true,
    required: true,
    name: 'Email Address',
    setState: setFormData,
    placeholder: 'Enter your email',
    stateValue: formData?.emailAddress,
  };

  const submitButtonProps = {
    type: 'submit',
    variant: 'primary',
    value: 'Send Link',
    className: styles?.submitButton,
    isDisabled: forgotPassLogs?.isLoading,
  };

  const handleHideModal = () => {
    setShowModal(false);
  };

  renderOnPasswordChange(formData, setPasswordCheck);

  renderSubmitCallback(forgotPassLogs, handleLogin, setShowModal, dispatch);

  return (
    <Container fluid className={styles?.container}>
      <div className={styles?.forgotPassContainer}>
        <Form className={styles?.forgotPassForm} onSubmit={handleSubmit}>
          <div className={styles?.loginHeader}>
            <h6>Reset Password</h6>
            <p>Enter your email address and we will send al ink to reset your password</p>
          </div>

          <div className={styles?.formContainer}>
            <FormText {...emailProps} />
          </div>

          <div className={styles?.marginContainer}>
            <ButtonSubmit {...submitButtonProps} />

            <div className={styles?.signup}>
              <p>Remembered your password?</p>

              <Link className={styles?.link} to='/'>
                Sign in
              </Link>
            </div>
          </div>

          <p className={styles?.copyright}>
            2023 © {process.env.REACT_APP_NAME}. All Rights Reserved.
          </p>
        </Form>

        <div className={styles.imageContainer}>
          <Image src={LoginImage} />
        </div>
      </div>

      <SuccessModal show={showModal} onHide={handleHideModal} />
    </Container>
  );
};

const renderOnPasswordChange = (formData, setPasswordCheck) => {
  useEffect(() => {
    if (formData?.password === '') {
      setPasswordCheck({
        value: '',
        contains: [],
      });
    }

    if (formData?.password) {
      const result = passwordStrength(formData?.password, passwordOptions);

      setPasswordCheck(result);
    }
  }, [formData?.password]);
};

/* eslint-disable no-unused-vars */
const renderSubmitCallback = (forgotPassLogs, handleLogin, setShowModal, dispatch) => {
  useEffect(() => {
    const { error, isFailed, isSuccess } = forgotPassLogs;

    if (isSuccess) {
      setShowModal(true);

      setTimeout(() => {
        handleLogin();
        setShowModal(false);
      }, 2000);

      dispatch(resetForgotState());
    }

    if (isFailed) {
      toast.error(error);
      dispatch(resetForgotState());
    }
  }, [forgotPassLogs]);
};

export default ForgotPassword;
