/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Button, Image, Table } from 'react-bootstrap';

import ImageUI from 'components/GlobalUIs/ImageUI';
import { selectRequest } from 'reducers/requestReducer';
import samplePicture from 'assets/images/placeholder.png';
import TablePagination from 'components/GlobalUIs/Pagination';

import styles from './ForApproval.module.scss';

const ForApproval = ({
  data,
  setFilter,
  setShowRequest,
  setShowApprover,
  handlePaginationClick,
}) => {
  const requestState = useSelector(selectRequest);
  const { meta, logs } = requestState;

  console.log('data', data);

  return (
    <Table responsive className={styles?.approval}>
      <thead>
        <tr>
          <th>Image</th>
          <th>Item Code</th>
          <th>Item Name</th>
          <th>Date of Requested</th>
          <th>Borrowed By</th>
          <th>Type of Transaction</th>
          <th>Stock Needed</th>
          <th>Approver</th>
          <th>Status</th>
        </tr>
      </thead>
      <tbody>
        {data && logs && logs?.isLoading === false && data.length === 0 && (
          <tr>
            <td colSpan={10}>No Data Found.</td>
          </tr>
        )}

        {data &&
          data.length >= 1 &&
          data.map((dd) => (
            <tr key={dd?.id}>
              <td>
                <ImageUI src={dd?.image} alt={dd?.itemName} fallbackSrc={samplePicture} />
              </td>
              <td>{dd?.itemCode}</td>
              <td>{dd?.itemName}</td>
              <td>{dd?.dateRequested}</td>
              <td>{dd?.borrowedBy}</td>
              <td>{dd?.typeOfTransaction}</td>
              <td>{dd?.stockNeeded}</td>
              <td>
                <Button
                  onClick={() => {
                    setShowApprover({
                      show: true,
                      data: dd?.approver,
                    });
                  }}
                  className={styles?.button}
                >
                  Approver
                </Button>
              </td>

              <td>
                <span className={styles[dd?.status === 'Inprogress' ? 'InProgress' : dd?.status]}>
                  {dd?.status === 'Pending' ? dd?.status : dd?.attributes?.approver_status}
                </span>
              </td>
            </tr>
          ))}
      </tbody>
      <tfoot>
        <tr>
          <td colSpan={10}>
            <TablePagination
              meta={meta}
              setParams={setFilter}
              loading={logs?.isLoading}
              handlePaginationClick={handlePaginationClick}
            />
          </td>
        </tr>
      </tfoot>
    </Table>
  );
};

export default ForApproval;
