/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
// Import core & vendor packages below
import { toast } from 'react-toastify';
import { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Image, Container } from 'react-bootstrap';
import { passwordStrength } from 'check-password-strength';

// Components
import FormText from 'components/GlobalUIs/FormText';
import SuccessModal from './components/SuccessModal';
import ButtonSubmit from 'components/GlobalUIs/ButtonSubmit';
import { signup, selectAuth, resetSignUpState } from 'reducers/authReducer';

// Import assets below
import styles from './index.module.scss';
import LoginImage from 'assets/images/login Image.png';
import { ReactComponent as UserSVG } from 'assets/svg/username.svg';
import { ReactComponent as LockSVG } from 'assets/svg/lock.svg';

const initialState = {
  password: '',
  emailAddress: '',
  confirmPassword: '',
};

const passwordOptions = [
  {
    id: 0,
    value: 'Too weak Password',
    minDiversity: 0,
    minLength: 0,
  },
  {
    id: 1,
    value: 'Weak Password',
    minDiversity: 1,
    minLength: 4,
  },
  {
    id: 2,
    value: 'Medium Password',
    minDiversity: 3,
    minLength: 6,
  },
  {
    id: 3,
    value: 'Strong Password',
    minDiversity: 4,
    minLength: 8,
  },
];

// Main component
const SignUp = () => {
  const dispatch = useDispatch();

  const authState = useSelector(selectAuth);
  const { signUpLogs } = authState;

  const navigate = useNavigate();

  const [showModal, setShowModal] = useState(false);
  const [formData, setFormData] = useState(initialState);
  const [passwordCheck, setPasswordCheck] = useState({
    value: '',
    contains: [],
  });

  const handleLogin = () => {
    navigate('/');
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const formDataVal = new FormData();

    formDataVal.append('email', formData?.emailAddress);
    formDataVal.append('password', formData?.password);
    formDataVal.append('password_confirmation', formData?.confirmPassword);

    dispatch(signup(formDataVal));
  };

  const emailProps = {
    Icon: UserSVG,
    type: 'email',
    haveIcon: true,
    required: true,
    name: 'Email Address',
    setState: setFormData,
    placeholder: 'Enter your email',
    stateValue: formData?.emailAddress,
  };

  const passwordProps = {
    Icon: LockSVG,
    haveIcon: true,
    required: true,
    type: 'password',
    name: 'Password',
    setState: setFormData,
    placeholder: 'Password',
    stateValue: formData?.password,
  };

  const confirmPasswordProps = {
    Icon: LockSVG,
    haveIcon: true,
    type: 'password',
    setState: setFormData,
    name: 'Confirm Password',
    placeholder: 'Confirm Password',
    stateValue: formData?.confirmPassword,
    required: true,
  };

  const submitButtonProps = {
    type: 'submit',
    variant: 'primary',
    value: 'Create Account',
    className: styles?.submitButton,
    isDisabled: signUpLogs?.isLoading,
  };

  const handleHideModal = () => {
    setShowModal(false);
    handleLogin();
  };

  renderOnPasswordChange(formData, setPasswordCheck);

  renderSubmitCallback(signUpLogs, handleLogin, setShowModal, dispatch);

  return (
    <Container fluid className={styles?.container}>
      <div className={styles?.signUpContainer}>
        <Form className={styles?.signUpForm} onSubmit={handleSubmit}>
          <div className={styles?.loginHeader}>
            <h6>Create Account</h6>
          </div>

          <div className={styles?.formContainer}>
            <FormText {...emailProps} />

            <FormText {...passwordProps} />

            {passwordCheck && (
              <div className={styles?.passwordCheck}>
                <ul>
                  {passwordCheck?.contains.map((dd, index) => (
                    <li key={index}>{}</li>
                  ))}
                </ul>
                <p>{passwordCheck?.value}</p>
              </div>
            )}

            <FormText {...confirmPasswordProps} />
          </div>

          <div className={styles?.marginContainer}>
            <ButtonSubmit {...submitButtonProps} />

            <div className={styles?.signup}>
              <p>Already have an account?</p>

              <Link className={styles?.link} to='/'>
                Sign in
              </Link>
            </div>
          </div>

          <p className={styles?.copyright}>
            2023 © {process.env.REACT_APP_NAME}. All Rights Reserved.
          </p>
        </Form>

        <div className={styles.imageContainer}>
          <Image src={LoginImage} />
        </div>
      </div>

      <SuccessModal show={showModal} onHide={handleHideModal} handleLogin={handleLogin} />
    </Container>
  );
};

const renderOnPasswordChange = (formData, setPasswordCheck) => {
  useEffect(() => {
    if (formData?.password === '') {
      setPasswordCheck({
        value: '',
        contains: [],
      });
    }

    if (formData?.password) {
      const result = passwordStrength(formData?.password, passwordOptions);

      setPasswordCheck(result);
    }
  }, [formData?.password]);
};

/* eslint-disable no-unused-vars */
const renderSubmitCallback = (signUpLogs, handleLogin, setShowModal, dispatch) => {
  useEffect(() => {
    const { error, isFailed, isSuccess } = signUpLogs;

    if (isSuccess) {
      setShowModal(true);

      // eslint-disable-next-line multiline-comment-style
      // setTimeout(() => {
      //   handleLogin();
      //   setShowModal(false);
      // }, 2000);

      dispatch(resetSignUpState());
    }

    if (isFailed) {
      toast.error(error);
      dispatch(resetSignUpState());
    }
  }, [signUpLogs]);
};

export default SignUp;
