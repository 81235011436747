import React from 'react';

import '../Styles/Footer.scss';

const Footer = () => {
  return (
    <div className='footer'>
      <p>2023 © {process.env.REACT_APP_NAME}. All Rights Reserved.</p>
    </div>
  );
};

export default Footer;
