/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
// Import core & vendor packages below
import { toast } from 'react-toastify';
import { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Image, Container } from 'react-bootstrap';
import { passwordStrength } from 'check-password-strength';

// Components
import FormText from 'components/GlobalUIs/FormText';
import SuccessModal from './components/SuccessModal';
import ButtonSubmit from 'components/GlobalUIs/ButtonSubmit';
import { updatePassword, selectAuth } from 'reducers/authReducer';

// Import assets below
import styles from './index.module.scss';
import LoginImage from 'assets/images/login Image.png';
import { ReactComponent as UserSVG } from 'assets/svg/username.svg';
import { ReactComponent as LockSVG } from 'assets/svg/lock.svg';

const initialState = {
  password: '',
  emailAddress: '',
  confirmPassword: '',
};

const passwordOptions = [
  {
    id: 0,
    value: 'Too weak Password',
    minDiversity: 0,
    minLength: 0,
  },
  {
    id: 1,
    value: 'Weak Password',
    minDiversity: 1,
    minLength: 4,
  },
  {
    id: 2,
    value: 'Medium Password',
    minDiversity: 3,
    minLength: 6,
  },
  {
    id: 3,
    value: 'Strong Password',
    minDiversity: 4,
    minLength: 8,
  },
];

// Main component
const UpdatePassword = () => {
  const dispatch = useDispatch();

  const authState = useSelector(selectAuth);
  const { updatePasswordLogs } = authState;

  const navigate = useNavigate();

  const [showModal, setShowModal] = useState(false);
  const [formData, setFormData] = useState(initialState);
  const [passwordCheck, setPasswordCheck] = useState({
    value: '',
    contains: [],
  });

  const handleLogin = () => {
    navigate('/');
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const params = {
      token: formData?.token,
      email: formData?.email,
      password: formData?.password,
      password_confirmation: formData?.confirmPassword,
    };

    dispatch(updatePassword(params));
  };

  const emailProps = {
    type: 'email',
    name: 'Email',
    haveClass: '',
    setState: setFormData,
    stateValue: formData?.email,
    placeholder: 'Email Address',
    required: true,
  };

  const passwordProps = {
    Icon: LockSVG,
    haveIcon: true,
    required: true,
    type: 'password',
    name: 'Password',
    setState: setFormData,
    placeholder: 'Password',
    stateValue: formData?.password,
  };

  const confirmPasswordProps = {
    Icon: LockSVG,
    haveIcon: true,
    type: 'password',
    setState: setFormData,
    name: 'Confirm Password',
    placeholder: 'Confirm Password',
    stateValue: formData?.confirmPassword,
    required: true,
  };

  const submitButtonProps = {
    type: 'submit',
    variant: 'primary',
    value: 'Reset Password',
    className: styles?.submitButton,
    isDisabled: updatePasswordLogs?.isLoading,
  };

  const handleHideModal = () => {
    setShowModal(false);
  };

  renderSubmitCallback(updatePasswordLogs, handleLogin, setShowModal);

  renderOnPasswordChange(formData, setPasswordCheck);

  // Render when token is found/not found
  useEffect(() => {
    if (window) {
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      const token = urlParams.get('token');
      setFormData((prev) => {
        return {
          ...prev,
          token: token ? token : '',
        };
      });
    }
  }, [window]);

  return (
    <Container fluid className={styles?.container}>
      <div className={styles?.updatePasswordContainer}>
        <Form className={styles?.updatePasswordForm} onSubmit={handleSubmit}>
          <div className={styles?.loginHeader}>
            <h6>Reset Password</h6>
            <p>Enter your new password and not match to your previously used password.</p>
          </div>

          <div className={styles?.formContainer}>
            <FormText {...emailProps} />

            <FormText {...passwordProps} />

            {passwordCheck && (
              <div className={styles?.passwordCheck}>
                <ul>
                  {passwordCheck?.contains.map((dd, index) => (
                    <li key={index}>{}</li>
                  ))}
                </ul>
                <p>{passwordCheck?.value}</p>
              </div>
            )}

            <FormText {...confirmPasswordProps} />
          </div>

          <div className={styles?.marginContainer}>
            <ButtonSubmit {...submitButtonProps} />

            <div className={styles?.signup}>
              <p>Already have an account?</p>

              <Link className={styles?.link} to='/'>
                Sign in
              </Link>
            </div>
          </div>

          <p className={styles?.copyright}>
            2023 © {process.env.REACT_APP_NAME}. All Rights Reserved.
          </p>
        </Form>

        <div className={styles.imageContainer}>
          <Image src={LoginImage} />
        </div>
      </div>

      <SuccessModal show={showModal} onHide={handleHideModal} />
    </Container>
  );
};

const renderOnPasswordChange = (formData, setPasswordCheck) => {
  useEffect(() => {
    if (formData?.password === '') {
      setPasswordCheck({
        value: '',
        contains: [],
      });
    }

    if (formData?.password) {
      const result = passwordStrength(formData?.password, passwordOptions);

      setPasswordCheck(result);
    }
  }, [formData?.password]);
};

/* eslint-disable no-unused-vars */
const renderSubmitCallback = (updatePasswordLogs, handleLogin, setShowModal) => {
  useEffect(() => {
    const { error, isFailed, isSuccess } = updatePasswordLogs;

    if (isSuccess) {
      setShowModal(true);

      setTimeout(() => {
        handleLogin();
      }, 2000);
    }

    if (isFailed) {
      toast.error(error);
    }
  }, [updatePasswordLogs]);
};

export default UpdatePassword;
