/* eslint-disable no-unused-vars */
import axios from 'axios';
import { toast } from 'react-toastify';
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Row,
  Col,
  Form,
  Image,
  Table,
  Modal,
  Button,
  Container,
  FormControl,
} from 'react-bootstrap';

import { approveUser, getUsersState } from 'reducers/usersReducer';

import './AddRoles.scss';

const AddRoles = ({ show, handleClose, data, handleUsers }) => {
  const dispatch = useDispatch();

  const userData = useSelector(getUsersState);
  const { approveLogs } = userData;

  const [formData, setFormData] = useState({
    roles: [],
    userRoles: '',
  });

  const handleChange = (e) => {
    const { value, name } = e.target;

    setFormData((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  };

  const handleSave = async () => {
    if (formData?.userRoles === '') {
      toast.error('Please select role');
      return;
    }

    const objData = {
      id: data?.id,
      params: { role: formData?.userRoles },
    };

    try {
      const response = await dispatch(approveUser(objData));

      if (response) {
        handleUsers();
        handleClose();
        toast.info('Successfully approved user');
      }

      setFormData((prev) => ({ ...prev, userRoles: '' }));
    } catch (error) {
      toast.error(error);
    }
  };

  useEffect(() => {
    const getRoles = async () => {
      try {
        axios.get('api/roles').then((response) => {
          setFormData((prev) => ({ ...prev, roles: response?.data?.data }));
        });
      } catch (error) {
        console.log('Error', error);
      }
    };

    getRoles();
  }, []);

  return (
    <Modal show={show} onHide={handleClose} centered className='updateRoles'>
      <Modal.Header closeButton>
        <Modal.Title>Assign Role</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className='body'>
          <Row className='w-100'>
            <Col>
              <Form.Group className='mb-4 group'>
                <Form.Label>Roles</Form.Label>
                <Form.Select
                  name='userRoles'
                  onChange={handleChange}
                  placeholder='Enter Here'
                  value={formData?.userRoles}
                >
                  <option value='' hidden>
                    Select Role
                  </option>
                  {formData &&
                    formData?.roles &&
                    formData?.roles.length >= 1 &&
                    formData.roles?.map((dd) => (
                      <option key={dd?.id} value={dd?.attributes?.name}>
                        {dd?.attributes?.name}
                      </option>
                    ))}
                </Form.Select>
              </Form.Group>

              <Form.Group className='group'>
                <Button
                  onClick={handleSave}
                  className='updateRoles'
                  disabled={approveLogs?.isLoading}
                >
                  Save
                </Button>
              </Form.Group>
            </Col>
          </Row>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default AddRoles;
