import React, { useEffect, useState } from 'react';

import { Image } from 'react-bootstrap';

import sampleImage from 'assets/images/placeholder.png';

const ImageUI = ({ src, alt, fallbackSrc = '', ...rest }) => {
  const [error, setError] = useState(false);
  const [placeholder, setPlaceholder] = useState(sampleImage);

  const handleError = () => {
    setError(true);
  };

  useEffect(() => {
    if (fallbackSrc) {
      setPlaceholder(placeholder);
    }
  }, [fallbackSrc]);

  return error ? (
    <Image src={placeholder} alt={alt} {...rest} />
  ) : (
    <Image src={src} alt={alt} onError={handleError} {...rest} />
  );
};

export default ImageUI;
