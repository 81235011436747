/* eslint-disable newline-per-chained-call */
/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
/* eslint-disable multiline-comment-style */

// Core & Vendor Packages
import axios from 'axios';
import { toast } from 'react-toastify';
import { useState, useEffect, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Button, Image, Container, Row, Col, FormControl, Table } from 'react-bootstrap';

// Component
import { getLogs } from 'reducers/auditLogsReducer';
import ItemHistory from './components/ItemHistory';
import { objectCleaner } from 'utils/objectCleaner';
import ReturnedItems from './components/ReturnedItems';
import BorrowedItems from './components/BorrowedItems';
import ConditionalRender from 'components/ConditionalRender';
import PrivateLayout from 'components/GlobalUIs/PrivateLayout';
import SamplePagination from 'components/GlobalUIs/SamplePagination';

// Assets
import styles from './index.module.scss';
import samplePicture from 'assets/images/bondpaper.png';
import { ReactComponent as ListSVG } from 'assets/svg/list.svg';
import { ReactComponent as PrintSVG } from 'assets/svg/print.svg';
import { ReactComponent as DownloadSVG } from 'assets/svg/download.svg';

// Main component
const Reports = () => {
  const dispatch = useDispatch();

  const timerRef = useRef(null);

  const [selectTab, setSelectTab] = useState('Borrowed Item');

  const [borrowedData, setBorrowedData] = useState([]);

  const [returnData, setReturnData] = useState([]);

  const [filter, setFilter] = useState({
    q: '',
    to: '',
    page: 1,
    from: '',
    sort_by: '',
    per_page: '5',
    transaction_type: 'BORROWED',
    order_by: 'user_profile.status',
  });

  const handleDates = (e) => {
    const { name, value } = e.target;

    const _dateRange = {
      to: filter?.to,
      from: filter?.from,
    };

    _dateRange[name] = value;

    setFilter((prev) => ({ ...prev, [name]: value }));

    // Reset timer when user type before 1 second occur
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }

    // Run function after 1 second if the timer isn't reset
    timerRef.current = setTimeout(() => {
      if (_dateRange?.from !== '' && _dateRange?.to !== '') {
        setFilter((prev) => ({ ...prev, page: 1 }));

        const newObj = {
          page: 1,
          to: _dateRange?.to,
          from: _dateRange?.from,
          sort_by: filter?.sort_by,
          per_page: filter?.per_page,
          transaction_type: filter?.transaction_type,
        };

        const params = objectCleaner(newObj);
        tryCatch(params);
      }
    }, 1000);
  };

  const tryCatch = async (params, transaction_type = filter?.transaction_type) => {
    if (transaction_type === 'BORROWED') {
      setBorrowedData([]);
    }

    if (transaction_type === 'RETURNED') {
      setReturnData([]);
    }

    try {
      const response = await dispatch(getLogs(params));

      console.log('response?.payload', response?.payload);

      const newData = response?.payload?.data?.data?.map((dd) => {
        return {
          ...dd,
          id: dd?.id,
          itemCode: dd?.attributes?.item_id,
          image: dd?.attributes?.item_image,
          item_name: dd?.attributes?.item_name,
          created_at: dd?.attributes?.created_at,
          borrowed_by: dd?.attributes?.borrowed_by,
          dateRequested: dd?.attributes?.created_at,
          borrower_role: dd?.attributes?.borrower_role,
          typeOfTransaction: dd?.attributes?.transaction_type,
          status: dd?.attributes?.properties?.attributes?.status,
          stockNeeded: dd?.attributes?.properties?.attributes?.quantity,
        };
      });

      if (transaction_type === 'BORROWED') {
        setBorrowedData(newData);
      }

      if (transaction_type === 'RETURNED') {
        setReturnData(newData);
      }
    } catch (error) {
      toast.error('Something went wrong.');
      console.log('error', error);
    }
  };

  const handleRequest = () => {
    const unfinalParams = {
      q: filter?.search,
      page: filter?.page,
      sort_by: filter?.sort_by,
      per_page: filter?.per_page,
      transaction_type: filter?.transaction_type,
    };

    const params = objectCleaner(unfinalParams);

    tryCatch(params);
  };

  const handlePaginationClick = ({ page, transaction_type = '' }) => {
    const newObj = {
      page: page,
      q: filter?.search,
      sort_by: filter?.sort_by,
      per_page: filter?.per_page,
      transaction_type: transaction_type !== '' ? transaction_type : filter?.transaction_type,
    };

    const params = objectCleaner(newObj);

    const _type = transaction_type !== '' ? transaction_type : filter?.transaction_type;

    tryCatch(params, _type);
  };

  const handleExport = () => {
    if (filter?.from === '' && filter?.to !== '') {
      toast.warning('Please select dates');
      return;
    }

    if (filter?.from !== '' && filter?.to === '') {
      toast.warning('Please select dates');
      return;
    }

    const newObj = {
      to: filter?.to,
      from: filter?.from,
      transaction_type: filter?.transaction_type,
    };

    const params = objectCleaner(newObj);

    axios({
      method: 'get',
      params: params,
      responseType: 'blob',
      url: '/api/activity/export',
    })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'file.xlsx');
        document.body.appendChild(link);
        link.click();

        window.URL.revokeObjectURL(url);
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  };

  useEffect(() => {
    handleRequest();
  }, []);

  console.log('filter', filter);

  return (
    <PrivateLayout pageTitle='Reports'>
      <div className={styles?.transaction}>
        <Container>
          <Row>
            <Col className={styles?.columm}>
              <div className={styles?.profileContainer}>
                <div className={styles?.header}>
                  <div className={styles?.search}>
                    <div className={styles?.buttonContainer}>
                      <Button
                        onClick={() => {
                          setSelectTab('Borrowed Item');
                          setFilter((p) => ({
                            ...p,
                            to: '',
                            from: '',
                            transaction_type: 'BORROWED',
                          }));
                          handlePaginationClick({ page: 1, transaction_type: 'BORROWED' });
                        }}
                        className={`${selectTab === 'Borrowed Item' ? styles?.active : ''}`}
                      >
                        Borrowed Item
                      </Button>
                    </div>
                    <div className={styles?.buttonContainer}>
                      <Button
                        onClick={() => {
                          setSelectTab('Returned Item');
                          setFilter((p) => ({
                            ...p,
                            from: '',
                            to: '',
                            transaction_type: 'RETURNED',
                          }));
                          handlePaginationClick({ page: 1, transaction_type: 'RETURNED' });
                        }}
                        className={`${selectTab === 'Returned Item' ? styles?.active : ''}`}
                      >
                        Returned Item
                      </Button>
                    </div>
                    {/* <div className={styles?.buttonContainer}>
                      <Button
                        onClick={() => setSelectTab('Item History')}
                        className={`${selectTab === 'Item History' ? styles?.active : ''}`}
                      >
                        Item History
                      </Button>
                    </div> */}
                  </div>

                  <div className={styles?.filter}>
                    <div className={styles?.from}>
                      <p className={styles?.title}>From</p>

                      <FormControl
                        name='from'
                        type='date'
                        value={filter?.from}
                        onChange={handleDates}
                        className={styles?.fromInput}
                        max={new Date().toISOString().slice(0, 10)}
                      />
                    </div>

                    <div className={styles?.to}>
                      <p className={styles?.title}>To</p>

                      <FormControl
                        name='to'
                        type='date'
                        value={filter?.to}
                        min={filter?.from}
                        className={styles?.toInput}
                        max={new Date().toISOString().slice(0, 10)}
                        onChange={(e) => {
                          if (filter?.from !== '') {
                            handleDates(e);
                            return;
                          }

                          toast.warning('Please select from first');
                        }}
                      />
                    </div>

                    <div className={styles?.tableActions}>
                      <Button onClick={handleExport} className={styles?.download}>
                        <DownloadSVG />
                        Export
                      </Button>
                    </div>
                  </div>
                </div>

                <div className={styles?.body}>
                  <ConditionalRender
                    condition={selectTab === 'Borrowed Item'}
                    renderIf={
                      <BorrowedItems
                        data={borrowedData}
                        setFilter={setFilter}
                        handlePaginationClick={handlePaginationClick}
                      />
                    }
                  />

                  <ConditionalRender
                    condition={selectTab === 'Returned Item'}
                    renderIf={
                      <ReturnedItems
                        data={returnData}
                        setFilter={setFilter}
                        handlePaginationClick={handlePaginationClick}
                      />
                    }
                  />
                  {/* 
                  <ConditionalRender
                    condition={selectTab === 'Item History'}
                    renderIf={<ItemHistory data={itemHistoryData} />}
                  /> */}
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </PrivateLayout>
  );
};

export default Reports;
